<template>
    <div>
        <scholarship-page>
            <template v-slot:header>
                <div>
                    <Breadcrumbs
                        :items="items"
                    />
                    <h1>{{ $t('vernadskiTitle') }}</h1>
                    <h2 class="sec-title">{{ $t('vernandskiTitleSec') }}</h2>
                </div>
            </template>

            <template v-slot:content>
                <p class="content-text" v-html="$t('vernadskiProgramText1')"></p>
                <p class="content-text">{{ $t('vernadskiProgramText2') }}</p>

                <div class="mobile-table_wrapper vernadski-table_wrapper">
                    <p class="content-text">{{ $t('vernadskiProgramText3') }}</p>
                    <p class="content-text" v-html="$t('vernadskiProgramText4')"></p>


                    <p class="content-title table-title">II — {{ $t('forCandidats') }}</p>

                    <div class="table-content">
                        <div class="table-wrap">
                            <div class="table-row">
                                <div class="table-row-title">{{ $t('columnTitle1') }}</div>
                                <div class="table-row-title">{{ $t('columnTitle2') }}</div>
                                <div class="table-row-title">{{ $t('columnTitle3') }}</div>
                            </div>
                            <div class="table-row">
                                <div class="table-row-item">
                                    <p>{{ $t('vernadskiTableItem1') }}</p>
                                    <p>{{ $t('vernadskiTableItem2') }}</p>
                                    <p>{{ $t('vernadskiTableItem3') }}</p>
                                    <p>{{ $t('vernadskiTableItem4') }}</p>
                                </div>
                                <div class="table-row-item">
                                    <p>{{ $t('vernadskiForm') }}</p>
                                    <p>{{ $t('cv') }}</p>
                                    <p>{{ $t('vernadskiTableItem5') }}</p>
                                    <p>{{ $t('vernadskiTableItem6') }}</p>
                                    <p>{{ $t('vernadskiTableItem7') }}</p>
                                    <p>{{ $t('vernadskiTableItem8') }}</p>
                                    <p>{{ $t('vernadskiTableItem9') }}</p>
                                    <p>{{ $t('vernadskiTableItem10') }}</p>
                                </div>
                                <div class="table-row-item">
                                    <p>{{ $t('visa') }}</p>
                                    <p>{{ $t('vernadskiTableItem11') }}</p>
                                    <p>{{ $t('vernadskiTableItem12') }}</p>
                                    <p>{{ $t('vernadskiTableItem13') }}</p>
                                    <p>{{ $t('vernadskiTableItem14') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="info-wrap">
                        <div class="info-img-wrap">
                            <img src="@/assets/images/Vernadski.png"/>
                        </div>
                        <div>
                            <p class="name">{{ $t('vernadskiName') }}</p>
                            <p class="info">
                                {{ $t('vernadskiDescription') }}
                            </p>
                        </div>
                    </div>
                </div>
            </template>

        </scholarship-page>
    </div>
</template>

<script>
import ScholarshipPage from "@/components/ScholarshipPage";
import Breadcrumbs from '@/components/breadcrumbs';

export default {
    components: {
        ScholarshipPage,
        Breadcrumbs,
    },
    data: () => ({
        items: [
            {
                text: 'home',
                href: '/'
            },
            {
                text: 'boursesEtudes',
                href: '#',
            },
            {
                text: 'vernadski',
                href: '#',
            }
        ]
    }),
}
</script>

<style lang="scss">
.sec-title {
  line-height: 40px;
}
.vernadski-table_wrapper {
    @media(max-width: 1024px) {
        margin-top: 190px!important;
    }
    @media (max-width: 768px) {
        margin: 0!important;
    }
}
</style>